import checkIfEdge from './checkIfEdge';

// Using "a misty rain" from http://lbsyun.baidu.com/apiconsole/custommap -> Map template
import mapStyleBaidu from './baidu-maps-style.json';

// Fix issue with Edge (not IE!) not working with certain mapStyles
// that we use. Should be removed once non-Chrome-Edge is history.
export default function baiduMapsStyleGetter() {
  return checkIfEdge() ? mapStyleBaidu.slice(0, 59) : mapStyleBaidu;
}
